const updateObject = (initialObject, newParams) => {
    return {
      ...initialObject,
      ...newParams,
    };
}
const emailValidation = text => {
  let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (reg.test(text) !== false) {
      return true;
  }
  return false;
};
const validatePhone = (phone) => {
  console.log('get phone number', phone);
  const phoneNumber = phone.replace('+', '')
  const pattern = new RegExp(/^[0-9\b]+$/);
  if (!pattern.test(phoneNumber)) {
      return false
  } else {
      return true
  }
}
const fullNameValidation = text => {
const pattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
if (pattern.test(text) !== false) {
  return true;
}
return false;

}

const generateHexUUID = () =>
    Array.from({ length: 32 }, () => Math.floor(Math.random() * 16).toString(16)).join('');



export { updateObject, emailValidation, validatePhone, fullNameValidation, generateHexUUID, }