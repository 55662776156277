import { postDataRoles } from "./requests";

export const getJobRolesByCat = (_cat, _mode, _type) => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': _cat,
        'mode': _mode,
        'type': _type
    };
    return postDataRoles(url, requestData)
}

export const getJobRolesJobRoles = () => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': 'jobrole',
        'mode': 1,
        'type': 'front-end'
    };
    return postDataRoles(url, requestData)
}

export const getJobRolesJobTitles = () => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': 'jobtitle',
        'mode': 1,
        'type': 'front-end'
    };
    return postDataRoles(url, requestData)
}

export const getJobRolesIndustries = () => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': 'jobindustry',
        'mode': 1,
        'type': 'front-end'
    };
    return postDataRoles(url, requestData)
}

export const getJobRolesEducation = () => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': 'educationlevel',
        'mode': 1,
        'type': 'front-end'
    };
    return postDataRoles(url, requestData)
}

export const getJobRolesSkillLevel = () => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': 'skilllevel',
        'mode': 1,
        'type': 'front-end'
    };
    return postDataRoles(url, requestData)
}
