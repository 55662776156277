import { useState } from "react";
import { toast } from "react-toastify";
import brand from "../../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../shared-comps/back-btn";
import { updateSeekerJobDataByKey as updateSeekerJobDataByKeyApi, 
    updateSeekerJobDataByKeyValue as updateSeekerJobDataByKeyValue  } from "../../../../../api/job-seeker";

const SeekerSignUpStep8 = ({_userId, _onResp}) => {
const companySizes = ['1-20 employee', '21-50 employee', '51-100 employee', '101-250 employee', '251+ employee']
const [listedCompanySize, setListedCompanySize] = useState(companySizes)
const [companySize, setCompanySize] = useState(companySizes[1])

function onProceedClick(){
    if([undefined, null, ''].includes(companySize)){
        toast.warning('Please Select A Company Size')
        return
    }
    updateSeekerAssDetails()
    updateCreateProfileStep()
}

function updateSeekerAssDetails(){
    const data = {'campanySize': companySize}
    updateSeekerJobDataByKeyApi(_userId, data).then(() => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

function updateCreateProfileStep(){
    const obj = {'selfAssessmentResultSteps': 9}
    updateSeekerJobDataByKeyValue(_userId, obj).then((_resp) => {

    }).catch((_resp) => {

    })
}

return <>
<section id='job-role-steps' className='bg-position-1'>
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className='discover-personality-top-cnt'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <img src={brand} alt='brand' className='w-80px' />
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-2' />
            <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                    <form id='msform'>
                        <fieldset>
                            <div className='form-card'>
                                <h3 className="job-que">What size company would you like to work for?</h3>
                                <div className='role-wrapper'>
                                    {listedCompanySize &&
                                    listedCompanySize.map((data, i) => (
                                        <div key={i} className='role-col'>
                                            <div key={i + 2} className='inputGroup'>
                                                <input key={i + 3} id={data?._id}  name='radio'  type='radio' readOnly checked={companySize.includes(data)} />
                                                <label key={i + 4}  onClick={() => setCompanySize(data)}  htmlFor={data?._id}>
                                                    {data}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <input  type='button'  name='next'  className='next action-button mt-2' value='' onClick={() => onProceedClick()}/>
                        </fieldset>
                    </form>
                </div>
                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                Step 8/16
                            </h2>
                        </div>
                    </div>
                    <div className='progress border'>
                        <div className='progress-bar' role='progressbar' style={{ width: '50%' }}  aria-valuenow='50' aria-valuemin='0' aria-valuemax='100' />
                    </div>
                </div>
            </div>
            <div className='col-lg-2' />
        </div>
    </div>
</section>
</>
}

export default SeekerSignUpStep8