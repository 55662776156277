import { useState } from "react";
import { toast } from "react-toastify";
import brand from "../../../../../assets/images/logo-icon-blk.png";
import ProfileIcon from '../../../../../assets/images/profile-img-standard.png';
import UploadIcon from '../../../../../assets/images/profile-img-upload-icon.png';
import ArrowIcon from '../../../../../assets/images/right-arrow.png';
import { uploadAndProcessSeekerCv as uploadAndProcessSeekerCv, 
    updateSeekerJobDataByKeyValue as updateSeekerJobDataByKeyValue, 
    uploadSeekerPfp as uploadSeekerPfpApi  } from "../../../../../api/job-seeker";

const SeekerSignUpStep1 = ({_userId, _onResp }) => {
const validCvExtensions = ['.docx', '.txt', '.text', '.pdf'];
const validPfpExtensions = ['.webp', '.png', '.jpeg', '.jpg'];
const [fileName, setFileName] = useState("");
const [cv, setCv] = useState(null)
const [pfp, setPfp] = useState(null)

const getImageUrl = (e) => {
    const file = e.target.files[0];
    setPfp(file)
};

const getFileSelected = (e) => {
    const file = e.target.files[0];
    if (file) {
        setFileName(file.name);
        setCv(file)
    }
};

const getFileExtension = (file) => {
    return file ? file.name.slice(file.name.lastIndexOf('.')).toLowerCase() : null;
};

const saveUserData = () => {
    if (!cv || !getFileExtension(cv) || !validCvExtensions.includes(getFileExtension(cv))) {
    toast.warning('Invalid CV format. Allowed: .docx, .txt, .text, .pdf');
    return;
}

    if (!pfp || !getFileExtension(pfp) || !validPfpExtensions.includes(getFileExtension(pfp))) {
        toast.warning('Invalid Pfp format. Allowed: .webp, .png, .jpeg, .jpg');
        return;
    }
    updateSeekerAssDetails()
    uploadSeekerPfp()
    updateCreateProfileStep()
};

function updateSeekerAssDetails(){
    _onResp('next', null);
    uploadAndProcessSeekerCv(_userId, cv).then((_resp) => {
        console.log(_resp.data)
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        
    })
}

function uploadSeekerPfp(){
    uploadSeekerPfpApi(_userId, pfp).then((_resp) => {

    }).catch((_resp) => {

    })
}

function updateCreateProfileStep(){
    const obj = {'selfAssessmentResultSteps': 2}
    updateSeekerJobDataByKeyValue(_userId, obj).then((_resp) => {

    }).catch((_resp) => {

    })
}

return (
<section id="feel-suite-best">
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" style={{width: "80px"}}/>
                </div>
            </div>
        </div>
    </div>
    <div className="container mt-4 mb-4">
        <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8 upload-your-profile text-center mt-2">
                <h4>Add a photo</h4>
                <div className="profile-img mt-0">
                    <div className="d-block">
                        <div className="profile-image-container mt-2 mb-2">
                            {pfp !== null?
                                <img src={URL.createObjectURL(pfp)} alt="profile" />
                                :
                                <img src={ProfileIcon} alt="profile" />
                            }
                        </div>

                        <div className="file" style={{ cursor: "pointer" }}>
                            <img src={UploadIcon} alt="upload" style={{ cursor: "pointer" }} />
                            <input style={{ cursor: "pointer" }}  type="file" name="file" accept="image/*" onChange={getImageUrl}/>
                        </div>
                    </div>
                </div>

                <div className="form-group upload-cv-col mt-2">
                    <h6 className="mb-4 mt-2">CV Upload</h6>
                    <div className="input-group">
                        <div className="custom-file">
                            <div>
                                <p>{fileName || "Attach Document"}</p>
                                <input type="file" className="custom-file-input" id="inputGroupFile01" onChange={getFileSelected} accept=".doc,.docx,.ppt,.pptx,.pdf,.md"/>
                            </div>
                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                <span className="hidden-xs">Browse</span>
                            </label>
                        </div>
                    </div>
                </div>
                <p>
                    <button onClick={saveUserData} className="btn btn-primary mt-0">
                        <img src={ArrowIcon} alt="Next" style={{'height': '60px'}} />
                    </button>                   
                </p>

                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                Step 1/16
                            </h2>
                        </div>
                    </div>
                    <div className='progress border'>
                        <div className='progress-bar' role='progressbar' style={{ width: '6.25%' }}  aria-valuenow='6.25' aria-valuemin='0' aria-valuemax='100' />
                    </div>
                </div>
            </div>
            <div className="col-lg-2" />
        </div>
    </div>
</section>
);
};

export default SeekerSignUpStep1;