import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import Blk from '../../../assets/images/logo-icon-blk.png';
import {useDispatch, useSelector} from 'react-redux';
import ProfileIcon from '../../../assets/images/profile-img-standard.png';
import PlusBtn from '../../../assets/images/plus-btn.png';
import UploadIcon from '../../../assets/images/profile-img-upload-icon.png';
import {toast} from 'react-toastify';
import GoBack from '../../../components/GoBack';
import {saveUserInfo} from '../../../redux/actions';
import {postApi, API_ADD_DOCUMNET_JOBSEEKER, API_ADD_IMAGE_DOCUMNET_JOBSEEKER} from '../../../utils';
import moment from 'moment';

function HardStepFive() {
	const {state} = useLocation();
	const jobId = state.jobId;
	const [profileImage, setProfileImage] = useState('');
	const [documentSelected, setDocumentSelected] = useState('');
	const [fileName, setFileName] = useState('');
	const userToken = useSelector(state => state.UserAuth.userData.payload.token);
	const userAuth = useSelector(state => state.UserAuth);

	const isLogo = userAuth?.userData?.payload?.user?.selfAssessmentResult
		? userAuth?.userData?.payload?.user?.selfAssessmentResult
		: false;

	const [imageFile, setImageFile] = useState('');
	const [isUploadImage, setUploadImage] = useState(false);
	const [isUploadDocument, setUploadDocument] = useState(false);
	const [choosenDate, setChoosenDate] = useState(undefined);
	const [choosenLogo, setChoosenLogo] = useState(undefined);
	const [jobDescription, setJobDescription] = useState("");
	const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

	const userType = useSelector(state => state.userType.userType);
	const [isLoading, setLoading] = useState(false);
	const fileRef = useRef();
	const navigation = useNavigate();
	const dispatch = useDispatch();

	let startDateToday = moment(new Date()).format('YYYY-MM-DD')
	var dataMax = new Date();
	var dateMax1 = dataMax.setMonth(dataMax.getMonth() + 24);
	let startDateMax = moment(dateMax1).format('YYYY-MM-DD');

	//*********************Setting image url**************************/
	const getImageUrl = e => {
		if (e.target.files[0]) {
			const object = e.target.files[0];
			const objectUrl = URL.createObjectURL(object);
			setImageFile(e.target.files[0]);
			setProfileImage(objectUrl);
		}
	};
	//Watch chnges when Profile image changes
	useEffect(() => {
		if (profileImage) {
			setUploadImage(true);
		}
	}, [profileImage]);
	//************************Get file selected *********************************** */
	const getFileSelected = e => {
		const fileName = e.target.files[0].name;
		const fileSelected = e.target.files[0];
		setFileName(fileName);
		setDocumentSelected(fileSelected);
	};
	//**********************Watch cheges when file changes***************************//
	useEffect(() => {
		if (documentSelected) {
			setUploadDocument(true);
		}
	}, [fileName, documentSelected]);
	//*****************Save user data in server*************** */
	const userAuthInfo = useSelector(state => state.UserAuth);
	const saveUserData = () => {
		if (imageFile) {
			setLoading(true);
			var myHeaders = new Headers();
			myHeaders.append('type', 'front-end');
			myHeaders.append('mode', '1');
			myHeaders.append('Authorization', `Bearer ${userToken}`);

			var formdata = new FormData();
			formdata.append('image', imageFile);
			formdata.append('userId', userId);
			formdata.append('document', documentSelected);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow',
			};

			fetch(API_ADD_DOCUMNET_JOBSEEKER, requestOptions).then(response => response.json())
				.then(result => {
					console.log('---------', result);
					var userInfo = result.data;
					var existingUserInfo = userAuthInfo.userData?.payload;
					existingUserInfo.user.imagePath = userInfo.imagePath;
					existingUserInfo.user.selfAssessmentResult = true;
					dispatch(
						saveUserInfo({
							payload: existingUserInfo,
							isRemember: userAuthInfo.userData?.isRemember,
						})
					);

					setLoading(false);
					toast.success('Your profile data is saved succesfully', {
						position: 'top-right',
						autoClose: 1000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

					navigation('../profileComplete', {state: result?.data});
				}).catch(error => {
					setLoading(false);
					toast.error('Oops something went wrong!', {
						position: 'top-right',
						autoClose: 1000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				});
		} else {
			toast.error('Upload profile picture!', {
				position: 'top-right',
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};
	//****************************JOB POSTER FUNCTION********************************** */
	useEffect(() => {
	}, [choosenLogo]);
	const getSelectedLogo = e => {
		const fileName = e.target.files[0].name;
		const fileSelected = e.target.files[0];
		setFileName(fileName);
		setChoosenLogo(fileSelected);
	};
	//Redirecting to company info page
	const savePosterInfo = () => {
		if (choosenDate === "" || choosenDate == null) {
			toast.error("Please provide a Start Date!", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else if (jobDescription === "" || jobDescription == null) {
			toast.error("Please provide job description!", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			const obj = {
//      upload: choosenLogo,
				startDate: choosenDate,
			};
			var existingUserInfo = userAuthInfo.userData?.payload;
			existingUserInfo.user.selfAssessmentResult = true;
			dispatch(
				saveUserInfo({payload: existingUserInfo, isRemember: userAuthInfo.userData?.isRemember})
			);

			setLoading(true);
			var myHeaders = new Headers();
			myHeaders.append("type", "front-end");
			myHeaders.append("mode", "2");
			myHeaders.append("Authorization", `Bearer ${userToken}`);

			var formdata = new FormData();
			// formdata.append("upload", routerData.upload ? routerData.upload : "");
			formdata.append("jobDesc", jobDescription);
			formdata.append("startDate", choosenDate);
			formdata.append("userId", userId);
			formdata.append("jobId", jobId);
			// console.log("this si the start data", routerData.startDate);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(`${API_ADD_IMAGE_DOCUMNET_JOBSEEKER}`, requestOptions)
				.then((response) => {
					navigation("../profileCompleteCongrats", {state:{jobId: jobId}});
				})
				.then((result) => {
					setLoading(false);
					console.log("get upload response-------", result);
					setLoading(false);

					toast.success("Record updated successfully", {
						position: "top-right",
						autoClose: 1000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

					navigation("../profileCompleteCongrats", {state:{jobId: jobId}});
				});

			// navigation('../companyInfo', {state: {...obj}});
		}
	};

	return (
		<React.Fragment>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center LoaderCenter">
					{' '}
					<div className="block">
						<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"/>
						<h6 className="LoaderText">Processing...</h6>
					</div>
				</div>
			)}
			{!isLoading && (
				<section id="feel-suite-best">
					<GoBack/>
					<div className="container">
						<div className="row d-flex algin-items-center justify-content-center">
							<div className="col-lg-8 text-center">
								<img src={Blk} alt="blk" className="w-80px"/>
								<h3 style={{fontSize: '2rem', lineHeight: '2.5rem'}}>
									{userType.payload === 'jobSeeker'
										? ' Upload your CV and add a mugshot'
										: ' Enter the start date and a Job Overview'}
								</h3>
							</div>
						</div>

						{userType.payload === 'jobSeeker' && (
							<div className="row" style={{marginTop: '-7%'}}>
								<div className="col-lg-2"/>
								<div className="col-lg-8 upload-your-profile text-center">
									<h4>Add a photo</h4>
									<div className="profile-img" style={{marginTop: '-7%'}}>
										<div className="d-block">
											<div className="profile-image-container">
												<img
													src={profileImage !== '' ? profileImage : ProfileIcon}
													alt={'profile'}
												/>
											</div>

											<div className="file" style={{cursor: 'pointer'}}>
												<img src={UploadIcon} alt="upload" style={{cursor: 'pointer'}}/>
												<input
													style={{cursor: 'pointer'}}
													type="file"
													name="file"
													accept="image/*"
													onChange={e => getImageUrl(e)}
												/>
											</div>
										</div>
									</div>

									<div className="form-group upload-cv-col" style={{marginTop: '-7%'}}>
										<h6>CV Upload</h6>
										<div className="input-group">
											<div className="custom-file">
												<div>
													<p>{fileName ? fileName : 'Attach Document'}</p>
													<input
														type="file"
														className="custom-file-input"
														id="inputGroupFile01"
														aria-describedby="inputGroupFileAddon01"
														onChange={e => getFileSelected(e)}
														accept=".doc,.docx,.ppt,.pptx,.pdf,.md" // Add this line
														/>

												</div>
												<label className="custom-file-label" for="inputGroupFile01">
													<span className="hidden-xs">Browse</span>
												</label>
											</div>
										</div>
									</div>

									<p>
										{' '}
										<a  onClick={saveUserData} className="add1">
											{' '}
											<img src={ArrowIcon} alt="right"/>
										</a>
									</p>
								</div>

								<div className="col-lg-2"/>
							</div>
						)}
						{userType.payload === 'jobPoster' && (
							<React.Fragment>
								<div className="row">
									<div className="col-lg-2"/>
									<div className="col-lg-8 upload-your-profile text-center">
										<h4>Start Date</h4>
										<div className="date-picker">

											<input
												type="date"
												id="start" name="trip-start"
												onChange={e => setChoosenDate(e.target.value)}
												// min="01-01-2022"
												min={startDateToday}
												max={startDateMax}
											/>
										</div>
									</div>

									<div className="col-lg-2"/>
								</div>
								<div className="row">
									<div className="col-lg-2"/>
									<div className="col-lg-8 text-center">
										<h4>Add a short, engaging overview of the job</h4>
										<div className="text-area">
											<textarea
												placeholder="Include a description of the job major funtion,how it contributes to the company's objectives and why it's important..."
												onChange={(e) => setJobDescription(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-lg-2"/>
									<p>
										{" "}
										<a  onClick={savePosterInfo}>
											{" "}
											<img src={ArrowIcon} alt={"Save & Next Button"}/>
										</a>
									</p>
								</div>
							</React.Fragment>
						)}
					</div>
				</section>
			)}
		</React.Fragment>
	);
}

export default HardStepFive;
