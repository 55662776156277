import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import brand from "../../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../shared-comps/back-btn";
// import Autosuggestion from '.../../../src/pages/private/locationAutoSuggestion/autosugestion';
import LocationSearch from '../../../../private/locationAutoSuggestion/autosugestion'
import { fetchjobposter as fetchjobposterApi } from "../../../../../api/jobs-data";
import { updateSeekerJobDataByKey as updateSeekerJobDataByKeyApi, 
    updateSeekerJobDataByKeyValue as updateSeekerJobDataByKeyValue   } from "../../../../../api/job-seeker"

const SeekerSignUpStep7 = ({_userId, _onResp}) => {

const [locationSelected, setLocationSelected] = useState('');
const [isRemoteWorker, setRemoteWorker] = useState(false);

useEffect(() => {
    loadJobData()
}, [])

function loadJobData(){
    fetchjobposterApi(_userId, 1).then((_resp) => {
        const records = _resp.data.result
        if(records.length === 0){
            return
        }else{
            const current_location = records[0]['locationName']
            setLocationSelected(current_location)
        }
    }).catch((_resp) => {

    })
}

const getLocationSelected = location => {
    setLocationSelected(location);
};

function onProceedClick(){
    if([undefined, null, ''].includes(locationSelected)){
        toast.warning('Please Provide A Location')
        return
    }
    updateSeekerAssDetails()
    updateCreateProfileStep()
}

function updateSeekerAssDetails(){
    const data = {
        'locationName': locationSelected,
        'remotely': isRemoteWorker
    }
    updateSeekerJobDataByKeyApi(_userId, data).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

function updateCreateProfileStep(){
    const obj = {'selfAssessmentResultSteps': 8}
    updateSeekerJobDataByKeyValue(_userId, obj).then((_resp) => {

    }).catch((_resp) => {

    })
}


return <>
<section id='job-role-steps' className='bg-position-1'>
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className='discover-personality-top-cnt'>
        <div className='container'>
            <div className='row'>
            <div className='col-lg-12 text-center'>
                <img src={brand} alt='brand' className='w-80px' />
            </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-6'>
                <div className='card' style={{ borderWidth: 0 }}>
                    <form id='msform'>
                        <fieldset>
                            <div className='form-card'>
                                <h3 className='job-que'>Where would you like the job to be located?</h3>
                                <div className='enter-job-role' style={{ transform: 'translateY(15%)' }}>
                                    <LocationSearch onLocationSelected={getLocationSelected} address={locationSelected}  />
                                    <label className='steps-checkbox'>
                                        <input onChange={e => setRemoteWorker(!isRemoteWorker)} checked={isRemoteWorker} type='checkbox'/>
                                        <span className='checkmark' />
                                        I'm happy to work remotely. 
                                    </label>
                                </div>
                            </div>

                            <input onClick={() => onProceedClick()} type='button' name='next' className='next action-button' value=''/>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                            Step 7/16
                        </h2>
                    </div>
                </div>
                <div className='progress border'>
                    <div className='progress-bar' role='progressbar' style={{ width: '43.75%' }}  aria-valuenow='43.75' aria-valuemin='0' aria-valuemax='100' />
                </div>
            </div>
        </div>
    </div>
</section>
</>
}

export default SeekerSignUpStep7