import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { getJobRolesEducation as getJobRolesEducationApi } from "../../../../api/job-roles";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";
import { generateHexUUID } from "../../../../utils";

const SeekerSignUpStep5 = ({_jobId, _onResp}) => {
const [jobEucationListed, setJobEucationListed] = useState([])
const [educationLevel, setEducationLevel] = useState([])
const [educationSelectedForInput, setEducationSelectedForInput] = useState()
const [userCreatedRole, setUserCreatedRole] = useState('')

useEffect(() => {
    getJobRoles()
}, [])

function getJobRoles(){
    getJobRolesEducationApi().then((_resp) => {
        if(_resp.data.message === 'success'){
            const records = _resp.data.result
            setJobEucationListed(records)
        }else{

        }
    }).catch(() => {

    })
}

function onProceedClick() {
    if([undefined, null, ''].includes(userCreatedRole)){
        toast.warning('Please Select Education Level')
        return
    }
    updateSeekerAssDetails()
}

function updateSeekerAssDetails(){
    const obj = {
        "_id": generateHexUUID(),
        "option": userCreatedRole,
        "category": "jobrole",
        "usertype": '',
        "priority": 1,
        "addedby": '',
        "userId": '',
        "status": 2,
        "orderBy": null
    }
    const data = {'educationLevel': obj}
    updatePosterJobDataByKeyApi(_jobId, data).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

return <>
<section id="job-role-steps">
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" className="w-80px" />
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-2" />
            <div className="col-lg-8">
                <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                        <fieldset>
                            <div className="form-card">
                                <h3 className="job-que">Select the highest level of education you have received</h3>
                                <div className="role-wrapper">
                                    {jobEucationListed.map((data, i) => (
                                        <div key={i} className="role-col">
                                            <div key={i + 1} className="inputGroup">
                                                <input key={i + 2} id={data?._id} name="radio" checked={educationLevel.includes(data._id)} readOnly type="radio" />
                                                <label key={i + 3} htmlFor={data?._id} className="text-capitalize"
                                                    onClick={() => {
                                                        setEducationSelectedForInput("");
                                                        setEducationLevel([data._id]);
                                                        setUserCreatedRole(data.option);
                                                    }} >
                                                    {data.option}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <input onClick={() => onProceedClick()} type="button" name="next" className="next action-button" value="" />
                        </fieldset>
                    </form>

                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                    Step 5/16
                                </h2>
                            </div>
                        </div>
                        <div className='progress border'>
                            <div className='progress-bar' role='progressbar' style={{ width: '31.25%' }}  aria-valuenow='31.25' aria-valuemin='0' aria-valuemax='100' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-2" />
        </div>
    </div>
</section>
</>

}

export default SeekerSignUpStep5