import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/brand-logo.png';
import smsIcon from '../../../assets/images/sms-icon.png';
import emailIcon from '../../../assets/images/email-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CountryCodes } from '../../private/countryCode/countryCode';
import {
  emailValidation,
  API_SEEKER_SEND_OTP,
  API_POSTER_SEND_OTP,
  postApiWithoutHeader,
  postApiPosterWithoutHeader,
  validatePhone,
} from '../../../utils';
import { useNavigate, useLocation } from 'react-router-dom';
import PhoneNumberInput from './../../../components/phoneNumber/phoneNumber';

function EmailPhoneVerify() {
  const navigation = useNavigate();
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [smsType, setSmsType] = useState('EMAIL');
  const [isLoading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const userType = useSelector(state => state.userType.userType);
  const { state } = useLocation();
  useEffect(() => {
    const { email, phone } = state;
    setEmail(email);
    setContactNumber(phone);
    console.log('get nwe value', state);
  }, []);
  const submitBtnAction = e => {
    setLoading(true);
    e.preventDefault();
    if (contactNumber !== undefined || email !== '') {
      let obj = {
        email: email,
        contactNumber: contactNumber,
        type: smsType,
      };

      if (userType.payload === 'jobSeeker') {
        postApiWithoutHeader(API_SEEKER_SEND_OTP, obj, successCallBack, errorCallBack);
      } else {
        postApiPosterWithoutHeader(API_POSTER_SEND_OTP, obj, successCallBack, errorCallBack);
      }
    } else {
      toast.error('Please provide requied detail!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const successCallBack = response => {
    console.log(response, 'resss');
    setLoading(false);
    let obj = {
      email: email,
      contactNumber: contactNumber,
      type: smsType,
    };
    toast.success(response.data.message, {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (smsType === 'SMS') {
      navigation('../phoneOtp', { state: { obj: obj } });
    } else {
      navigation('../emailOtp', { state: { obj: obj } });
    }
  };

  const errorCallBack = error => {
    console.log(error, 'errr');
    setLoading(false);
    console.log('get new respose', error);
    toast.error(error.response.data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  //Getting phone number
  const getContactNumber = number => {
    setContactNumber(number);
  };

  return (
    <section id='signup-page' className='emailVerify-page'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 text-center'>
            <div className='brand-logo'>
              <img className='img-fluid' src={brand} alt='brand' style={{ width: '180px' }} />
            </div>
            <form onSubmit={submitBtnAction} className=''>
              <div className='row d-flex justify-content-center'>
                <div className='col-lg-8'>
                  <br />
                  <p style={{ fontSize: '0.85rem', textTransform: 'initial' }}>
                    Please verify your account in order to get the best out of your experience with
                    Perspectv
                  </p>
                  <br />
                </div>
                {/* <div className='col-lg-6 col-sm-6'>
                  <div className='signup-input-bx'>
                    <label>Mobile Number</label>
                    <input name='contactNumber' type='tel' placeholder='Enter phone number' value={state.phone}  disabled maxLength={16}></input>
                  </div>
                </div> */}

                <div className='col-lg-6 col-sm-6'>
                  <div className='signup-input-bx'>
                    <label>Email Address</label>
                    <input onChange={text => setEmail(text.target.value)} value={email} type='text' id='name' name='name' required disabled/>
                  </div>
                </div>

                <div className='col-lg-12 signup-verification-col '>
                  <p>How would you like to recieve your verification code?</p>
                  <div className='d-flex justify-content-center'>
                  <div className='wrapper mt-2'>
                    {/* <input type='radio' name='select' id='option-1' defaultChecked='checked' onClick={() => setSmsType('SMS')}/> */}
                    <input type='radio' name='select' id='option-2' defaultChecked='checked' onClick={() => setSmsType('EMAIL')} />
                    {/* <label htmlFor='option-1' className='option option-1'>
                      <div className='dot' />
                      <div>
                        <h6>
                          <img src={smsIcon} alt='sms' />
                          sms
                        </h6>
                        <p>You will recieve a code via SMS.</p>
                      </div>
                    </label> */}
                    <label htmlFor='option-2' className='option option-2'>
                      <div className='dot' />
                      <div>
                        <h6>
                          <img src={emailIcon} alt='email' />
                          email address
                        </h6>
                        <p>You will recieve a code via Email</p>
                      </div>
                    </label>
                  </div>
                  </div>
                </div>
                <br />
                <br />
                <p style={{ textTransform: 'inherit', marginTop: '-5%' }}>
                  By signing up you accept the <a href=''>terms of service</a> and{' '}
                  <a href=''>privacy policy</a>
                </p>
              </div>
              {isLoading ? (
                <button className='loading-btn' type='button' disabled>
                  <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true'/>
                  Processing..
                </button>
              ) : (
                <button className='form-btn' type='submit'>
                  Verify
                </button>
              )}
            </form>
            <br />
            <p className='login-signup-txt'>
              Already have an account? <strong onClick={() => navigation('../login')}>Login</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmailPhoneVerify;
