import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import topLogo from '../../../assets/images/logo-icon-blk.png';

import arrowIcon from '../../../assets/images/right-arrow.png';
import { useNavigate } from 'react-router-dom';
import Walkthrough from '../../../components/atoms/walkthrough';
import ModalStepTwo from '../loginstepmodal/modalSteptwo';
import ModalStepOne from './../loginstepmodal/modalStepOne';
import ModalStepThree from '../loginstepmodal/modalStepThree';
import ModalStepFour from '../loginstepmodal/modalStepFour';
import { useDispatch, useSelector } from 'react-redux';
import { userType } from '../../../redux/actions';
import JobPosterFirstModal from '../loginstepmodaljobposter/modalSeptepone';
import JobPosterSecondModal from './../loginstepmodaljobposter/modalSteptwo';
import JobPosterThirdModal from './../loginstepmodaljobposter/modalStepthree';
import JobPostFourthModal from '../loginstepmodaljobposter/modalStepfour';

function BridgePage() {
  const [optionSelected, setOptionSelected] = useState('jobSeeker');
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isModalTwoOpen, setisModalTwoOpen] = useState(false);
  const [isModalThreeOpen, setisModalThreeOpen] = useState(false);
  const [isModalFourOpen, setisModalFourOpen] = useState(false);
  // **********************************//Job Poster modal*******************************
  const [isJobPosterModalOpen, setJobPosterModalOpen] = useState(false);
  const [isJobPosterModalTwoOpen, setJobPosterModalTwoOpen] = useState(false);
  const [isJobPosterModalThreeOpen, setJobPosterModalThreeOpen] = useState(false);
  const [isJobPosterModalFourthOpen, setJobPosterModalFourthOpen] = useState(false);
  //******************************Function to handle modal one******************************
  const handleClose = () => setisModalOpen(false);
  const handleShow = () => setisModalOpen(true);
  //***********************Function for handle modal two*********************************
  const handleModalTwoShow = () => {
    setisModalOpen(false);
    setisModalTwoOpen(true);
  };
  const handleModalTwoClose = () => setisModalTwoOpen(false);
  //************************Function for handling third modal*******************************
  const handleModalThreeShow = () => {
    setisModalTwoOpen(false);
    setisModalThreeOpen(true);
  };
  const handleModalThreeClose = () => setisModalThreeOpen(false);
  //**************************handle the last modal no. fourth*******************************
  const handleModalFourShow = () => {
    setisModalThreeOpen(false);
    setisModalFourOpen(true);
  };
  const handleModalFourClose = () => {
    setisModalFourOpen(false);
  };

  //****************************************Job poster modal****************************************
  const openFirstModal = () => setJobPosterModalOpen(true);
  const closeFirstModal = () => setJobPosterModalOpen(false);
  const openSecondModal = () => {
    setJobPosterModalOpen(false);
    setJobPosterModalTwoOpen(true);
  };
  const closeSecondModal = () => {
    setJobPosterModalTwoOpen(false);
  };
  const openThirdModal = () => {
    setJobPosterModalTwoOpen(false);
    setJobPosterModalThreeOpen(true);
  };
  const closeThirdModal = () => setJobPosterModalThreeOpen(false);
  const closeFourthModal = () => {
    setJobPosterModalFourthOpen(false);
    setisModalFourOpen(false);
  };
  const openFourthModal = () => {
    setJobPosterModalThreeOpen(false);
    setJobPosterModalFourthOpen(true);
  };
  // **************************************Getting the user type***********************************************************
  const getUserType = value => {
    // storing the result in redux store
    dispatch(userType({ payload: value })); // Type of user either it would be job seeker / job poster
    //when user type is job seeker
    if (value === 'jobSeeker') {
      // setisModalOpen(true);
      setOptionSelected(value);
    } else {
      //when user type is job poster
      setOptionSelected(value);
      // setJobPosterModalOpen(true);
    }
  };
  useEffect(() => { }, [optionSelected]);

  // *******************************check if selected value exists***********************************************************

  const choosenOption = useSelector(state => state.optionSelected.optionSelected);
  // console.log(choosenOption, 'choosenOption');

  const getUserSelection = () => {
    dispatch(userType({ payload: optionSelected })); // Type of user either it would be job seeker / job poster

    if (optionSelected === 'jobSeeker') {
      handleShow();
      if (choosenOption?.payload === 'Login') {
        navigation('../login');
      }
    } else if (optionSelected === 'jobPoster') {
      if (choosenOption?.payload === 'Login') {
        navigation('../login');
        // console.log('navigation');
      }
      openFirstModal();
    } else {
      //Show toast message
      toast.error('Please select any option!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div>
      <ModalStepOne
        showone={isModalOpen}
        show={isModalOpen}
        hideone={handleClose}
        nextstep={handleModalTwoShow}
      />
      <ModalStepTwo
        showtwo={isModalTwoOpen}
        hidetwo={handleModalTwoClose}
        stepthree={handleModalThreeShow}
      />
      <ModalStepThree
        showthree={isModalThreeOpen}
        hidethree={handleModalThreeClose}
        showfour={handleModalFourShow}
      />
      <ModalStepFour showfour={isModalFourOpen} hidefour={handleModalFourClose} />
      {/************************** Modal for job poster//*************** */}
      <JobPosterFirstModal
        show={isJobPosterModalOpen}
        showone={isJobPosterModalOpen}
        hideone={closeFirstModal}
        nextstep={openSecondModal}
      />
      <JobPosterSecondModal
        showtwo={isJobPosterModalTwoOpen}
        hidetwo={closeSecondModal}
        stepthree={openThirdModal}
      />
      <JobPosterThirdModal
        showthree={isJobPosterModalThreeOpen}
        hidethree={closeThirdModal}
        stepfour={openFourthModal}
      />
      <JobPostFourthModal showfour={isJobPosterModalFourthOpen} hidefour={closeFourthModal} />
      {/* //**********************************************MAIN SCREEN ***************************************************************** */}
      <section id='discover-personality-steps'>
        <Walkthrough />
        <div className='discover-personality-top-cnt'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <img src={topLogo} alt='topLogo' style={{ width: '80px' }} />
                <h1 className='login-module-heading font-space-mono'>
                  What we see depends on
                  <br className='d-none d-lg-block' /> what we look for
                </h1>
                <p style={{ marginTop: '1rem', lineHeight: '1.2rem' }}>
                  Discover how we use AI to shape the future of recruitment and <br /> turn problems
                  into possibilities.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div
            className='row text-center'
            style={{ position: 'relative', zIndex: 2, marginTop: '4%' }}
          >
            <div className='col-lg-2' />
            <div className='col-lg-8'>

              <h3 className='login-module-heading'>I am a...</h3>
              <div className='login-screen-btn tabs-btn'>
                <ul>
                  <li>
                    <a
                      className={optionSelected === 'jobSeeker' && 'active'}
                      onMouseEnter={() => setOptionSelected('')}
                      onClick={() => getUserType('jobSeeker')}
                      onMouseLeave={() => setOptionSelected('jobSeeker')}
                    >
                      Job Seeker
                    </a>
                  </li>
                  <li>
                    <a
                      className={optionSelected === 'jobPoster' && 'active'}
                      onMouseEnter={() => setOptionSelected('')}
                      onClick={() => getUserType('jobPoster')}
                      onMouseLeave={() => setOptionSelected('jobPoster')}
                    >
                      {/* Job Poster{' '} */}
                      Employer
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!--tabs-btn--> */}

              {/* <!-- Button trigger modal --> */}

              <button
                type='button'
                className='btn btn-primary'
                style={{ marginTop: '0px' }}
                onClick={getUserSelection}
              >
                <img src={arrowIcon} alt='arrowIcon' />
              </button>
            </div>
            {/* <!--col-8-close--> */}
            <div className='col-lg-2' />
          </div>
        </div>
        <Walkthrough />
        {/* <!-- Modal -->  tabindex="-1" */}

        {/* <!--modal--> */}
      </section>
    </div>
  );
}

export default BridgePage;
