import axios from "axios";

const getApi = (url, axiosConfig, successCallback, errorCallback) => {
  axios
    .get(url, axiosConfig)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      // console.log("GET api Response >> ", error);
      errorCallback(error);
    });
};

const postApi = (urlStr, params, userToken, successCallback, errorCallback, mode) => {
  // console.log("this is mode coming", mode)
  // console.log("mode", mode == null ? 1 : mode)
  let axiosConfig = {
    headers: {
      type: "front-end",
      mode: mode == null ? 1 : mode,
      Authorization: "Bearer " + userToken,
    },
  };
  axios
    .post(urlStr, params, axiosConfig)
    .then((response) => {
      // console.log("api Response >> ", response);
      if(successCallback) {
        successCallback(response);
      }
    })
    .catch((error) => {
      // console.log("api Error Response >> ", error);
      if(errorCallback) {
        errorCallback(error);
      }
    });
};

const postApiPosterMultipart = (
    urlStr,
    params,
    userToken,
    successCallback,
    errorCallback
) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      type: "front-end",
      mode: 2,
      Authorization: "Bearer " + userToken,
    },
  };
  axios.post(urlStr, params, axiosConfig).then((response) => {
    // console.log("api Response >> ", response);
    successCallback(response);
  })
  .catch((error) => {
    // console.log("api Error Response >> ", error);
    errorCallback(error);
  });
};

const postApiPoster = (
  urlStr,
  params,
  userToken,
  successCallback,
  errorCallback
) => {
  let axiosConfig = {
    headers: {
      type: "front-end",
      mode: 2,
      Authorization: "Bearer " + userToken,
    },
  };
  axios
    .post(urlStr, params, axiosConfig)
    .then((response) => {
      // console.log("api Response >> ", response);
      successCallback(response);
    })
    .catch((error) => {
      console.log("api Error Response >> ", error);
      errorCallback(error);
    });
};
const getApiWithoutHeader = (urlStr, successCallback, errorCallback) => {
  axios
    .get(urlStr)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      console.log("getApiWithoutHeader api Error Response >> ", error);
      errorCallback(error);
    });
};

const postApiWithoutHeader = (
  urlStr,
  params,
  successCallback,
  errorCallback
) => {
  let axiosConfig = {
    headers: {
      type: "front-end",
      mode: 1,
    },
  };
  axios
    .post(urlStr, params, axiosConfig)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};
const postApiPosterWithoutHeader = (
  urlStr,
  params,
  successCallback,
  errorCallback
) => {
  let axiosConfig = {
    headers: {
      type: "front-end",
      mode: 2,
    },
  };
  axios
    .post(urlStr, params, axiosConfig)
    .then((response) => {
      // console.log("Response after submitting: ------", response);
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export {
  postApiWithoutHeader,
  postApiPosterWithoutHeader,
  getApiWithoutHeader,
  getApi,
  postApi,
  postApiPoster,
  postApiPosterMultipart,
};
