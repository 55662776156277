import { publicPostData, publicPostFormData, postData } from "./requests";

export const getResultKeywordList = (_keyword) => {
    const url = 'api/jobseeker/getresultkeywordlist';
    const requestData = {
        'result': _keyword
    };
    return publicPostData (url, requestData)
}


export const uploadAndProcessSeekerCv = (_user_id, _cv_file) => {
    const url = `api/jobseeker/upload/process/cv/${_user_id}`;
    const formData = new FormData();
    formData.append('cv_file', _cv_file);
    return publicPostFormData (url, formData)
}

export const uploadSeekerPfp = (_user_id, _pfp_file) => {
    const url = `api/jobseeker/upload/pfp/${_user_id}`;
    const formData = new FormData();
    formData.append('pfp_file', _pfp_file);
    return publicPostFormData (url, formData)
}

export const updateSeekerJobDataByKey = (_user_id, _obj) => {
    const url = 'api/jobseeker/update/by/key';
    const requestData = {
        'seeker_id': _user_id,
        'data': _obj
    };
    return postData (url, requestData)
}

export const updateSeekerJobDataByKeyValue = (_user_id, _obj) => {
    const url = 'api/jobseeker/update/by/key/value';
    const requestData = {
        'seeker_id': _user_id,
        'data': _obj
    };
    return postData (url, requestData)
}