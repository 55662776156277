import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import Blk from '../../../../assets/images/logo-icon-blk.png'
import { getResultKeywordList as getResultKeywordListApi } from "../../../../api/job-seeker"
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";

const SeekerSignUpStep12 = ({_jobId, _onResp}) => {
const [checkedItems, setCheckedItems] = useState([]);
const [stepData, setstepData] = useState([]);

useEffect(() => {
    getResultKeywordList()
}, [])

function getResultKeywordList(){
    const value = localStorage.getItem(`selfAssesWizardResult_${_jobId}`);
    getResultKeywordListApi(value).then((_resp) => {
        const data = _resp.data.ptb1
        setstepData(data);
    }).catch((_resp) => {

    })
}

const renderItem = (item, index) => {
    return (
        <div key={index} className='col-lg-3 col-sm-6 col-12'>
            <div className='inputGroup'>
                <input id={`check${index + 1}`} name='checkbox' type='checkbox' readOnly checked={checkedItems.includes(item)}/>
                <label htmlFor={`check${index + 1}`} onClick={() => getChekedItems(item)}>
                    {item}
                </label>
            </div>
        </div>
    );
};

const getChekedItems = item => {
    // console.log(item, 'itemsss');
    if (checkedItems.includes(item)) {
        const index = checkedItems.indexOf(item);
        checkedItems.splice(index, 1);

        setCheckedItems([...checkedItems]);
    } else {
        if (checkedItems.length < 3) {
            setCheckedItems([...checkedItems, item]);
        } else {
            toast.error('You can select only 3 options!', { position: 'top-right', autoClose: 1000,  hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});
        }
    }
};

function onProceedClick(){
    if(checkedItems.length !== 3){
        toast.warning('Please Select Required Skills')
        return
    }
    updateSeekerAssDetails()
}

function updateSeekerAssDetails(){
    const obj = {'feelsuits1': checkedItems}
    updatePosterJobDataByKeyApi(_jobId, obj).then((_resp) => {
        
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        _onResp('next', null)
    })
}

return <>
<section id='feel-suite-best'>
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className="discover-personality-top-cnt">
        <div className='container'>
            <div className='row d-flex algin-items-center justify-content-center'>
                <div className='col-lg-6 text-center'>
                    <img src={Blk} alt='blk' className='w-80px' />
                    <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
                        Select 3 that you feel suits you the best
                    </h3>
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-12 suite-best-wrapper'>
                    <div className='row text-capitalize d-flex justify-content-center'>
                        {stepData.map((item, index) => renderItem(item, index))}
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <fieldset id="msform">
                        <input type="button" className="next action-button" onClick={() => onProceedClick()} />
                    </fieldset>
                </div>
            </div>

            <div>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                            Step 11/16
                        </h2>
                    </div>
                </div>
                <div className='progress border'>
                    <div className='progress-bar' role='progressbar' style={{ width: '68.75%' }}  aria-valuenow='68.75' aria-valuemin='0' aria-valuemax='100' />
                </div>
            </div>
        </div>
    </div>
</section>
</>
}

export default SeekerSignUpStep12