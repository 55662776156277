import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import Swal from 'sweetalert2';
import GoBack from "../../../../components/GoBack";
import { GetProfileData as GetProfileDataApi } from "../../../../api/users";
import { getMatchesForJob as getMatchesForJobApi } from "../../../../api/matches";
// import { updateInterest as updateInterestApi } from '../../../../api/matches';
// import { buyProfile as buyProfileApi } from "../../../../api/poster";
import { removeFromWishlist as removeFromWishlistApi ,
    addToWishlist as addToWishlistApi, 
    getWishlist as getWishlistApi } from "../../../../api/wishlist";
import tallentListYes from '../../../../assets/images/talentlist1.jpg'
import tallentListNo from '../../../../assets/images/talentlist2.jpg'

const sideMatchingArray = [
    { title: "Current Matches", color: "#1ec991", type: "CM", heading: "Your Current Matches", noneToShowText: "Welcome to your Current Matches! While we don't currently have candidates that match your job requirements, rest assured that we're actively sourcing new talent every day." },
    { title: "Interested", color: "#5e43f8", type: "IN", heading: "Your Interested Matches", noneToShowText: "Your Interested Matches section is where you'll find all the candidates who have expressed interest in your roles, as well as any candidates you've shown interest in." },
    { title: "Mutual Interest", color: "#ffdb59", type: "MI", heading: "Mutual Interest Matches", noneToShowText: "At this moment, no roles are awaiting mutual interest from both you and potential candidates. Once you have mutual interest you can proceed to purchasing and unlocking profiles." },
    { title: "Short Listed", color: "#d9453d", type: "SL", heading: "Your Shortlisted Jobs", noneToShowText: "While there are no candidates shortlisted just yet, don't worry—we're continuously introducing fresh talent. Keep an eye on your current & interested matches for new opportunities." },
];

function JobMatches() {
const navigate = useNavigate();
const { jobId } = useParams();
const { state } = useLocation();
const userType = useSelector(state => state.userType.userType);
// const userToken = useSelector(state => state.UserAuth.userData.payload.token);
const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

const [isLoading, setLoading] = useState(false);
const [selectedMenuItem, setSelectedMenuItem] = useState(sideMatchingArray[0]);
const [profileMatching, setProfileMatching] = useState([]);
// const [sortValue, setSortValue] = useState("");
const [isMobile, setIsMobile] = useState(false);
const [userProfileData, setUserProfileData] = useState(null);
// const [pageNumber, setPageNumber] = useState(1);
const [wishlist, setWishlist] = useState([])

const pageSize = 10;

useEffect(() => {
    const getUserFullData = async () => {
        try {
            const type = userType === "jobSeeker" ? 1 : 2;
            const response = await GetProfileDataApi(userId, type);
            if (response.data.data._id) {
                setUserProfileData(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    getUserFullData();
}, [userId, userType]);

useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    loadMatches("CM");

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
        window.removeEventListener("resize", handleResize);
    };
}, []);

useEffect(() => {
    loadWishlist()
}, [profileMatching])

function loadWishlist(){
    getWishlistApi().then((_resp) => {
        setWishlist(_resp['data']['data'])
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
}

function loadMatches(_filter) {
    getMatchesForJobApi(jobId, _filter).then((_resp) => {
        const records = _resp.data.seekers;
        setProfileMatching(records);
    }).catch((_resp) => {
        console.log(_resp);
    }).finally(() => {
        setLoading(false);
    });
}

const handleTabClick = (item) => {
    setSelectedMenuItem(item);
    setLoading(true);
    loadMatches(item.type);
};

// const paginatedObjects = useMemo(() => {
//     const startIndex = (pageNumber - 1) * pageSize;
//     const endIndex = startIndex + pageSize;

//     let newSortedValue = [];
//     if (sortValue === "alphabet") {
//         newSortedValue = profileMatching.sort((a, b) =>
//             a?.personalInfo?.[0]?.fullName.localeCompare(b?.personalInfo?.[0]?.fullName)
//         );
//     } else {
//         newSortedValue = profileMatching.sort((a, b) => b.percentage - a.percentage);
//     }

//     return newSortedValue.slice(startIndex, endIndex);
// }, [pageNumber, profileMatching, sortValue]);

const createMatchCardComp = (percent) => {
    const config = getPercentageImage(parseFloat(percent));
    // console.log('per: ', percent, '/ ', 'config: ', config);
    return (
        <div className="d-flex justify-content-between">
            <div className="text-end">
                <h5 className='text-end mt-3'>{config.name}</h5>
                <div className='text-center'>
                    <b className='float-end p-1 text-nowrap match-card-per-text' style={{ backgroundColor: config.color }}>{config.text}</b>
                </div>
            </div>
            <div>
                <img src={`/progress-images/${config.image}.png`} className="match-card-image" alt={config.name} />
            </div>
        </div>
    );
};

function addToWishlist(_job){
    const obj = {
        pointInfo: _job,
        seekerId: _job['seekerId'],
        posterId: _job['posterId'],
        associateId: _job['jobId'],
        jobId: _job['jobId'],
        buyProfile: 1,
        credit: 1,
    };
    addToWishlistApi(obj).then((_resp) => {
        toast.success('Added to Wishlist')
        loadMatches('MI')
    }).catch((_resp) => {
        toast.error('Failed to add to Wishlist')
        console.error(_resp)
    }).finally(() => {

    })
}

function onRemoveFromWishlist(_job){
    const obj = {
        seekerId: _job['seekerId'],
        pointInfo: _job['pointInfo'],
    };
    removeFromWishlistApi(obj).then((_resp) => {
        loadMatches('MI')
        toast.success('Removed From Wishlist')
    }).catch((_resp) => {
        toast.error('Failed to Remove from Wishlist')
    }).finally(() => {

    })
}

function isSeekerInWishlist(_seeker_id){
    const data = wishlist.filter(x => x['seekerId']['_id'] === _seeker_id)
    if(data.length > 0){
        return true
    }else{
        return false
    }
}

const renderItem = (item) => {
    const percent = item?.percentage.toFixed(0);
    let personalInfo = null
    if(item?.details === {}){
        personalInfo = {
            'fullName': ' ',
            'locationName': ''
        }
    }else{
        personalInfo = item?.details;
    }
    // debugger
    const info = {'locationName': personalInfo['location']};
    try {
        return (
            <div className="col-xl-6 col-lg-10 col-md-12 col-sm-12 col-xs-12" key={item._id}>
                <div className="match-card">
                    <div className="">
                        <div className="d-flex justify-content-between">
                            <div className="text-start">
                                <div style={{ cursor: 'pointer' }} onClick={() => profileClicked(item, selectedMenuItem.title)} className="match-name fs-5">
                                    {selectedMenuItem.title === "Short Listed" ? 
                                        <span className="fw-bold">{personalInfo.fullName}</span>
                                        : 
                                        <span className="fw-bold">{personalInfo.fullName.split(' ')[0]}</span>
                                    }
                                </div>
                                <div>{info?.locationName ?? ''}</div>
                                {selectedMenuItem.type === 'MI'?
                                    <>
                                        {isSeekerInWishlist(item['seekerId']) === false?
                                            <img onClick={() => {addToWishlist(item)}} style={{height: '70px', 'cursor': 'pointer'}} src={tallentListYes} alt="" />
                                        : null}
                                        {isSeekerInWishlist(item['seekerId']) === true?
                                            <img onClick={() => {onRemoveFromWishlist(item)}} style={{height: '70px', 'cursor': 'pointer'}} src={tallentListNo} alt="" />
                                        : null}
                                    </>
                                : null}
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={() => profileClicked(item, selectedMenuItem.title)}>
                                {createMatchCardComp(percent)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        console.log('idk error: ', error)
        return null
    }
};

const profileClicked = (item, selectedProfile) => {
    navigate(`./${item._id}`, { state: { job: item, selectedProfile } });
};

const renderSideItem = (item) => (
    <button onClick={() => handleTabClick(item)} id={`matching-btn-${item.type}`} className="matching-button" style={{ backgroundColor: item.color, transform: "scale(0.8)", marginTop: "-1%" }}>
        {item.title}
    </button>
);

return (
<React.Fragment>
    <GoBack />
    {userProfileData ?
        <div className="position-relative">
            <section id="dashboard-cnt-area" className="dashboard-bg">
                <div className="container-80">
                    <div className="row dashboard-cnt-row">
                        <div className="col-lg-3 col-sm-4 col-xs-12 dashboard-sidebar">
                            <div className="row" style={{ paddingRight: 40 }}>
                                {sideMatchingArray.map(renderSideItem)}
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center LoaderCenter">
                                <div className="block">
                                    <span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true" />
                                    <h6 className="LoaderText">Processing...</h6>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-9 col-sm-8 col-xs-12">
                                <div className="row">
                                    <div className="col-lg-12 d-flex" style={{ textAlign: "start" }}>
                                        <div>
                                            <a onClick={() => loadMatches(selectedMenuItem.type)}
                                                style={{
                                                    color: selectedMenuItem.color,
                                                    display: "inline-block",
                                                    textDecoration: 'none',
                                                    fontWeight: 'bold',
                                                    fontFamily: '\'Space Mono\', monospace',
                                                    fontSize: '1.5em',
                                                    textShadow: "1px 1px 0px #000, 2px 2px 0px #000",
                                                }}>
                                                {selectedMenuItem.heading}
                                            </a>
                                            <br />
                                            <span className="capitalize" style={{ fontFamily: "'Space Mono', monospace" }}>{state.company.name}</span>
                                            <br />
                                            <span className="capitalize" style={{ fontFamily: "'Space Mono', monospace" }}>{state.job.jobTitle.option} &ndash; {state.job.locationName}</span>
                                            {selectedMenuItem.title === "Mutual Interest" && userType === "jobSeeker" && (
                                                <div className="fx-profile-graph">
                                                    <h6>Done and dusted! Profile submitted for shortlisting.</h6>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 20 }} className="d-none d-lg-block"></div>
                                <div className="row">
                                    {profileMatching.map((item, index) => 
                                        <React.Fragment key={item._id || index}>
                                            {renderItem(item)}
                                        </React.Fragment>
                                    )}
                                </div>
                                {selectedMenuItem.title === "Mutual Interest" && userType !== "jobSeeker" && (
                                    <button onClick={() => navigate("../../wishlist")} className="matching-button talent-list-btn" style={{ background: "#02add4", fontSize: "20px", transform: "scale(0.8)", marginTop: "-1%" }}>
                                        Talent List
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
        : <h1 className='opacity-30'>Loading...</h1>}
</React.Fragment>
);
}

export default JobMatches;

function getPercentageImage(_percentage) {
    if (_percentage < 0 || _percentage > 100) {
        return "Invalid percentage";
    } else if (_percentage >= 0 && _percentage < 20) {
        return { 'image': "Very Low", 'text': <span>Incubating</span>, 'color': '#42d9ff', 'name': <span>SUNNY-SIDE <span style={{ color: '#42d9ff' }}>EGG</span></span> };
    } else if (_percentage >= 20 && _percentage < 50) {
        return { 'image': "LOW", 'text': <span>Low Match</span>, 'color': '#ffcc00', 'name': <span>LOW <span style={{ color: '#ffcc00' }}>MATCH</span></span> };
    } else if (_percentage >= 50 && _percentage < 55) {
        return { 'image': "EGG", 'text': <span>Incubating</span>, 'color': '#42d9ff', 'name': <span>SUNNY-SIDE <span style={{ color: '#42d9ff' }}>EGG</span></span> };
    } else if (_percentage >= 55 && _percentage < 56) {
        return { 'image': "sparrow", 'text': <span>Strong Match</span>, 'color': '#ff3300', 'name': <span>HOUSE <span style={{ color: '#ff3300' }}>SPARROW</span></span> };
    } else if (_percentage >= 56 && _percentage < 60) {
        return { 'image': "sparrow", 'text': <span>Strong Match</span>, 'color': '#ff3300', 'name': <span>HOUSE <span style={{ color: '#ff3300' }}>SPARROW</span></span> };
    } else if (_percentage >= 60 && _percentage < 61) {
        return { 'image': "sparrow", 'text': <span>Moderate Match</span>, 'color': '#ff9933', 'name': <span>MODERATE <span style={{ color: '#ff9933' }}>SPARROW</span></span> };
    } else if (_percentage >= 61 && _percentage < 70) {
        return { 'image': "CHICKEN", 'text': <span>Good Match</span>, 'color': '#ffcc80', 'name': <span>FIGHTING <span style={{ color: '#ffcc80' }}>ROOSTER</span></span> };
    } else if (_percentage >= 70 && _percentage < 71) {
        return { 'image': "CHICKEN", 'text': <span>Strong Match</span>, 'color': '#ff6600', 'name': <span>STRONG <span style={{ color: '#ff6600' }}>ROOSTER</span></span> };
    } else if (_percentage >= 71 && _percentage < 79) {
        return { 'image': "CROW", 'text': <span>Great Match</span>, 'color': '#42d9ff', 'name': <span>CUNNING <span style={{ color: '#42d9ff' }}>CROW</span></span> };
    } else if (_percentage >= 80 && _percentage < 89) {
        return { 'image': "PENGUIN", 'text': <span>Excellent Match</span>, 'color': '#3366ff', 'name': <span>PENGUIN <span style={{ color: '#3366ff' }}>KING</span></span> };
    } else if (_percentage >= 90 && _percentage <= 100) {
        return { 'image': "eagle", 'text': <span>Top Rated Match</span>, 'color': '#cc0000', 'name': <span>MIGHTY <span style={{ color: '#cc0000' }}>EAGLE</span></span> };
    }
}
