import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import BackBtn from "../../shared-comps/back-btn";
import brand from "../../../../../assets/images/logo-icon-blk.png";
import { getAllSelfAssessmentCategory as getAllSelfAssessmentCategoryApi, 
    getAllSelfAssesmentCategory as getAllSelfAssesmentCategoryApi } from "../../../../../api/selfassessment";
import { updateSeekerJobDataByKey as updateSeekerJobDataByKeyApi, 
    updateSeekerJobDataByKeyValue as updateSeekerJobDataByKeyValue  } from "../../../../../api/job-seeker"

const SeekerSignUpStep10 = ({_userId, _onResp}) => {
const [radioButton, setRadioButton] = useState([]);
const [isChecked, setChecked] = useState(false);
// const [jobTitle, setJobTitle] = useState("");
const [selfAssesmentData, setselfAssesmentData] = useState([]);
const [categoryList, setCategoryList] = useState([]);
const [quetionAsked, setQuetionAsked] = useState("");
const [optionOne, setOptionOne] = useState([]);
const [isActive, setActive] = useState([0]);
const [currentIndex, setCurrentIndex] = useState(0);
const [percentageComplete, setPercentageComplete] = useState(0);
const [selfAssesmenAdded, setSelfAssesmentAdded] = useState([]);
// const [newItemAdded, setItemAdded] = useState([]);
const [selectedOption, setSelectedOption] = useState({});
const [selectedAllOptionArray, setSelectedAllOptionArray] = useState([]);

useEffect(() => {
    getAllSelfAssessmentCategory()
    getAllSelfAssesmentCategory()
}, [])

useEffect(() => {
    if (currentIndex > 0) {
        setSelfAssesmentAdded([...selfAssesmenAdded, ...radioButton]);
        const currentQuestion = selfAssesmentData[currentIndex];
        setQuetionAsked(currentQuestion.question);
        setOptionOne(mapAnswersToOptions(currentQuestion.answers, currentQuestion.category));
        setRadioButton([currentQuestion.answers[0]]);
        setActive([0]);
        updatePercentageComplete();
    }
}, [currentIndex]);

useEffect(() => {
    setSelectedOption(radioButton);
}, [radioButton]);

const updatePercentageComplete = () => {
    const percentile = 100 / selfAssesmentData.length;
    if (currentIndex === 0) {
        setPercentageComplete(50);
    }
    setPercentageComplete((prev) => prev + percentile);
};

function getAllSelfAssessmentCategory(){
    getAllSelfAssessmentCategoryApi().then((_resp) => {
        setCategoryList(_resp.data.category);
    }).catch((_resp) => {
        console.log(_resp)
    })
}

function getAllSelfAssesmentCategory(){
    getAllSelfAssesmentCategoryApi().then((_resp) => {
        // const data = _resp.data
        const data = _resp.data.result
        updateDataArray(data);
        initializeFirstQuestion(data);
    }).catch((_resp) => {
        console.log(_resp)
    })
}

const mapAnswersToOptions = (answers, category) => {
    const levels = ["A", "B", "C", "D"];
    return answers.map((answer, index) => ({
        ...answer,
        level: levels[index],
        category_id: category,
    }));
};

const initializeFirstQuestion = (data) => {
    const firstQuestion = data[0];
    setQuetionAsked(firstQuestion.question);
    setOptionOne(mapAnswersToOptions(firstQuestion.answers, firstQuestion.category));
    setRadioButton([firstQuestion.answers[0]]);
    setActive([0]);
};

  // Update data array with category and level
const updateDataArray = (data) => {
    const updatedList = data.map((item) => ({
        ...item,
        answers: item.answers.map((subItem, index) => ({
            ...subItem,
            category_id: item.category,
            level: String.fromCharCode(65 + index), // "A" to "I"
        })),
    }));
    setselfAssesmentData(updatedList);
};

const pickFirstChar = (selfArray) => {
    let full_text = ''
    for(const x in selfArray){
        const obj = selfArray[x]
        let text = getCharFromOption(obj['result'])
        full_text = full_text + text.substring(0, 1)
    }
    return full_text;
};

const getCharFromOption = (option) => {
    switch (option) {
        case "Extrovert": return "E";
        case "Extroverted": return "E";
        case "Introverted": return "I";
        case "Observant": return "S";
        case "Sensors": return "S";
        case "Intuitive": return "N";
        case "Thinking": return "T";
        case "Feeling": return "F";
        case "Judging": return "J";
        case "Prospecting": return "P";
        default: return option.substring(0, 1);
    }
};

const saveInfo = () => {
    if (!isChecked) {
        toast.warning("Oops! You forgot to select the checkbox", { position: "top-right", autoClose: 1000,});
    } else {
        let dataLength = selfAssesmentData.length - 1;
        const combineStr = pickFirstChar([...selectedAllOptionArray, ...selectedOption]);
        if (currentIndex < dataLength) {
            setChecked(false);
            setCurrentIndex(currentIndex + 1);
            setSelectedAllOptionArray([...selectedAllOptionArray, ...selectedOption]);
        } else {
            if([undefined, null, ''].includes(combineStr)){
                toast.warning('Please Select Required Boxes')
                return
            }
            // console.log('combineStr: ', combineStr)
            localStorage.setItem(`selfAssesWizardResult_${_userId}`, combineStr);

            updateSeekerAssDetails(combineStr)
            updateCreateProfileStep()
        }
    }
};

function onRefreshClick(){
    getAllSelfAssessmentCategory()
    getAllSelfAssesmentCategory()
}

function updateSeekerAssDetails(_combine_str){
    const obj = {'selfAssessmentResult': _combine_str}
    updateSeekerJobDataByKeyApi(_userId, obj).then((_resp) => {
        
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        _onResp('next', null)
    })
}

function updateCreateProfileStep(){
    const obj = {'selfAssessmentResultSteps': 11}
    updateSeekerJobDataByKeyValue(_userId, obj).then((_resp) => {

    }).catch((_resp) => {

    })
}

return <>
<section id="job-role-steps">
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" style={{width: "80px"}}/>
                    <p>
                        {quetionAsked && quetionAsked}
                        <strong>Which scenario describes you best</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                        <fieldset>
                            <div className="form-card">
                            {optionOne.map((data, i) => (
                                <nav className="border-nav" key={i} onClick={() => setActive([i])}>
                                    <div className="nav nav-tabs">
                                        <button className={isActive.includes(i) ? "nav-link active" : "nav-link"}
                                            type="button"  onClick={() => { setChecked(false); setRadioButton([data]);}}>
                                            {data.level}
                                        </button>
                                    </div>
                                </nav>
                            ))}
                            {radioButton.map((data, i) => (
                                <div key={i + 3} className="tab-content" id="nav-tabContent">
                                    <div key={i + 4} className="tab-pane fade show active" id="step-1" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div key={i + 5} className="discover-check-cnt">
                                            <label key={i + 6} className="steps-checkbox">
                                                <input key={i + 7} type="checkbox" checked={isChecked} onChange={() => setChecked(!isChecked)}/>
                                                <span key={i + 8} className="checkmark" />
                                            </label>
                                            <p key={i + 9}>{data.options}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                            <input type="button" name="next" className="next action-button" value="" onClick={() => {saveInfo()}}/>
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="steps">
                                        Question {currentIndex + 1}
                                        {"/"}
                                        {selfAssesmentData.length}
                                    </h2>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                    Step 10/16
                                </h2>
                            </div>
                        </div>
                        <div className='progress border'>
                            <div className='progress-bar' role='progressbar' style={{ width: '62.5%' }}  aria-valuenow='62.5' aria-valuemin='0' aria-valuemax='100' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</>
}

export default SeekerSignUpStep10