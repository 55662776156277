import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import JobTitle from "./steps/job-title";
import CompanyCulture from "./steps/company-culture";
import ChooseIndustry from "./steps/choose-industry";
import EducationLevel from "./steps/education-level";
import SkillLevel from "./steps/skill-level";
import ChooseLocation from "./steps/choose-location";
import CompanySize from "./steps/company-size";
import SalaryComp from "./steps/salary";
import SelfAssessment from "./steps/self-assessment";
import HardSkillsStep1 from "./steps/hard-skills-step-1";
import HardSkillsStep2 from "./steps/hard-skills-step-2";
import HardSkillsStep3 from "./steps/hard-skills-step-3";
import HardSkillsStep4 from "./steps/hard-skills-step-4";
import HardSkillsStep from "./steps/hard-skills-step";
import SoftSkillsStep from "./steps/soft-skills-step";
import ShowDetailsEndComp from "./steps/show-details-end";

const PosterCreateJobWizard = () => {
const { company_id } = useParams();
const navigate = useNavigate();

// const stepIndex = parseInt(step) - 1;
const defaultStep = 0
const [currentStep, setCurrentStep] = useState(defaultStep);
const [jobId, setJobId] = useState(null)

const steps = [
    JobTitle, // 1
    CompanyCulture, // 2
    ChooseIndustry, // 3
    EducationLevel, // 4
    SkillLevel, // 5
    ChooseLocation, // 6
    CompanySize, // 7
    SalaryComp, // 8
    SelfAssessment, // 9
    HardSkillsStep1, // 10
    HardSkillsStep2, // 11
    HardSkillsStep3, // 12
    HardSkillsStep4, // 13
    HardSkillsStep, // 14
    SoftSkillsStep, // 15
    ShowDetailsEndComp, // 16
];

const StepComponent = steps[currentStep];

function onStepCompResp(_cmd, _value) {
    if(['next', 'back'].includes(_cmd)){
        setCurrentStep((prev) => {
            let nextStep = prev;
            if (_cmd === "next" && prev < steps.length - 1) {
                nextStep = prev + 1;
            } else if (_cmd === "back" && prev > 0) {
                nextStep = prev - 1;
            }else{
                navigate('/')
            }
            return nextStep;
        });
    }else if(_cmd === 'set/job-id'){
        setJobId(_value)
    }
}
return (<>
    <div className="wizard-container">
        {StepComponent && <StepComponent _companyId={company_id} _jobId={jobId} _onResp={onStepCompResp} />}
    </div>
</>)
}

export default PosterCreateJobWizard