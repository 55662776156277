import { postDataRoles, getPublicData } from "./requests";

export const getAllSelfAssessmentCategory = (_cat, _mode, _type) => {
    const url = 'api/admin/getallselfassessmentcategory';
    return getPublicData(url)
}

export const getAllSelfAssesmentCategory = (_cat, _mode, _type) => {
    const url = 'api/admin/self/getallselfassesmentcategory';
    const params = {
        'mode': 1,
        'type': 'front-end'
    }
    return postDataRoles(url, params)
}