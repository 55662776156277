const BackBtn = ({onClick}) => {
return <>
<button
      className="position-absolute"
      style={{
        width: "100px",
        backgroundColor: "transparent",
        top: "20px",
        left: "-11px",
        fontSize: "30px",
        zIndex: "333",
        border: "none",
      }}
      onClick={onClick}
    >
      <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
</button>

</>
}

export default BackBtn