import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/brand-logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  API_POSTER_SEND_OTP,
  API_POSTER_VERIFY_OTP,
  API_SEEKER_SEND_OTP,
  API_SEEKER_VERIFY_OTP,
  postApiWithoutHeader,
  postApiPosterWithoutHeader,
} from '../../../utils';
import OtpInput from 'react-otp-input';

function EmailPhoneOtp() {
  const [otp, setOtp] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const navigation = useNavigate();
  const userType = useSelector(state => state.userType.userType);
  const [isLoading, setLoading] = useState(false);

  const state = useLocation();
  const [userInfo] = useState(state);
  // console.log('userInfo: ', userInfo)
  const resendOtp = () => {
    setLoading(true);
    if (userType.payload === 'jobSeeker') {
      postApiWithoutHeader(API_SEEKER_SEND_OTP, userInfo.state.obj, successCallBack, errorCallBack);
    } else {
      postApiPosterWithoutHeader(
        API_POSTER_SEND_OTP,
        userInfo.state.obj,
        successCallBack,
        errorCallBack
      );
    }
  };
  const successCallBack = response => {
    setLoading(false);
    toast.success(response.data.message, {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const errorCallBack = error => {
    console.log('get new respose', error);
    setLoading(false);
    toast.error(error.response.data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const style = {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '7px solid #000 ',
    fontSize: '24px',
    boxShadow: 'none ',
    margin: '0 20px ',
    // height: '60px ',
    height: '46px',
    padding: '0',
    background: 'transparent',
    fontWeight: 'bold',
    color: 'black',
  };
  const checkOtpStatus = e => {
    setLoading(true);
    e.preventDefault();
    const { email, type } = userInfo.state.obj;
    let obj = {
      otp: otp,
      email: email,
      type: type,
    };
    console.log('get obj body', obj);
    if (otp.toString().length === 6) {
      if (userType.payload === 'jobSeeker') {
        postApiWithoutHeader(API_SEEKER_VERIFY_OTP, obj, successMessage, errorMessagee);
      } else {
        postApiPosterWithoutHeader(API_POSTER_VERIFY_OTP, obj, successMessage, errorMessagee);
      }
    }
  };
  const successMessage = response => {
    setLoading(false);
    if (response.status === 200) {
      navigation('../accountVerify');
    }
  };

  const errorMessagee = error => {
    console.log('get error response', error);
    setLoading(false);
    toast.error(error.response.data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    // console.log('get otp value-------', otp);
    if (otp.toString().length === 6) {
      setButtonDisabled(false);
    }
  }, [otp]);

  const handleChange = otp => {
    // console.log('get otp value----', otp);
    setOtp(otp);
  };

  return (
    <section id='login-page'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 '>
            <div className='brand-logo'>
              <img className='img-fluid' src={brand} alt='brand' />
            </div>
            <form onSubmit={checkOtpStatus}>
              <div className='otp-input'>
                <OtpInput
                  inputStyle={style}
                  focusStyle={false}
                  shouldAutoFocus={false}
                  onChange={handleChange}
                  numInputs={6}
                  value={otp}
                  isInputNum={true}
                />
              </div>
              <h6>
                <strong style={{ cursor: 'pointer' }} onClick={resendOtp}>
                  RESEND CODE
                </strong>
                <br />
                <br />
                Enter the 6 digit code we just sent to your email address
              </h6>
              {isLoading ? (
                <button className='loading-btn' type='button' disabled>
                  <span
                    className='spinner-grow spinner-grow-lg mx-2'
                    role='status'
                    aria-hidden='true'
                  />
                  Processing..
                </button>
              ) : (
                <button
                  type='submit'
                  className={isButtonDisabled ? 'form-btn disabled' : 'form-btn disabled'}
                  disabled={isButtonDisabled}
                  onClick={checkOtpStatus}
                >
                  Verify
                </button>
              )}
            </form>

            <p className='login-signup-txt'>
              <strong style={{ textDecoration: 'none' }} onClick={() => navigation(-1)}>
                Back
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmailPhoneOtp;
