import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CvProcessComp from "./steps/cv-process";
import CompanyCulture from "./steps/company-culture";
import JobTitle from "./steps/job-title";
import ChooseIndustry from "./steps/choose-industry";
import EducationLevel from "./steps/education-level";
import SkillLevel from "./steps/skill-level";
import ChooseLocation from "./steps/choose-location";
import CompanySize from "./steps/company-size";
import SalaryComp from "./steps/salary";
import SelfAssessment from "./steps/self-assessment";
import HardSkillsStep1 from "./steps/hard-skills-step-1";
import HardSkillsStep2 from "./steps/hard-skills-step-2";
import HardSkillsStep3 from "./steps/hard-skills-step-3";
import HardSkillsStep4 from "./steps/hard-skills-step-4";
import HardSkillsStep from "./steps/hard-skills-step";
import SoftSkillsStep from "./steps/soft-skills-step";
import ShowDetailsEndComp from "./steps/show-details-end";

const steps = [
    CvProcessComp, // 1
    CompanyCulture, // 2
    JobTitle, // 3
    ChooseIndustry, // 4
    EducationLevel, // 5
    SkillLevel, // 6
    ChooseLocation, // 7
    CompanySize, // 8
    SalaryComp, // 9
    SelfAssessment, // 10
    HardSkillsStep1, // 11
    HardSkillsStep2, // 12
    HardSkillsStep3, // 13
    HardSkillsStep4, // 14
    HardSkillsStep, // 15
    SoftSkillsStep, // 16
    ShowDetailsEndComp, // 17
];

const SeekerSignUpWizard = () => {
const { user_id, step } = useParams();
const navigate = useNavigate();

// console.log("user_id: ", user_id);
// console.log("URL step: ", step);

const stepIndex = parseInt(step) - 1; // Ensure it's 0-based
const [currentStep, setCurrentStep] = useState(stepIndex);

useEffect(() => {
    if (currentStep !== stepIndex) {
        setCurrentStep(stepIndex);
    }
}, [step]);

useEffect(() => {
    const newStep = currentStep + 1;
    if (newStep !== parseInt(step)) {
        navigate(`/create-profile/seeker/${user_id}/${newStep}`);
    }
}, [currentStep, user_id, step, navigate]);

const StepComponent = steps[currentStep];

function onStepCompResp(_cmd, _value) {
    if(['next', 'back'].includes(_cmd)){
        setCurrentStep((prev) => {
            let nextStep = prev;
            if (_cmd === "next" && prev < steps.length - 1) {
                nextStep = prev + 1;
            } else if (_cmd === "back" && prev > 0) {
                nextStep = prev - 1;
            }else{
                navigate('/')
            }
            return nextStep;
        });
    }
}

return (
    <div className="wizard-container">
        {StepComponent && <StepComponent _userId={user_id} _onResp={onStepCompResp} />}
    </div>
);
};

export default SeekerSignUpWizard;