import { postData } from "./requests";

export const buyProfile = (_payload) => {
    const url = "api/personal/buyProfile"
    return postData(url, _payload)
}

export const createPosterJobByJobTitle = (_poster_id, _company_id, _job_title) => {
    const url = 'api/job-poster/job/create/by/title';
    const requestData = {
        'poster_id': _poster_id,
        'company_id': _company_id,
        'job_title': _job_title
    };
    return postData (url, requestData)
}


export const updatePosterJobDataByKey = (_job_id, _obj) => {
    const url = 'api/job-poster/job/update/by/key';
    const requestData = {
        'job_id': _job_id,
        'data': _obj
    };
    return postData (url, requestData)
}