import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

class LocationSearchInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: props.address || '', // Set default value from props
			addressInit: false
		};
	}

	componentDidMount() {
		if (this.props.address && !this.state.addressInit) {
			this.setState({ address: this.props.address, addressInit: true });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.address !== this.props.address && !this.state.addressInit) {
			this.setState({ address: this.props.address, addressInit: true });
		}
	}

	handleChange = (address) => {
		this.setState({ address });
	};

	handleSelect = async (address, placeId) => {
		const [place] = await geocodeByPlaceId(placeId);

		const { long_name: postalCode = '' } =
			place.address_components.find((c) => c.types.includes('postal_code')) || {};
		const { long_name: locality = '' } =
			place.address_components.find((c) => c.types.includes('sublocality')) || {};
		const { long_name: state = '' } =
			place.address_components.find((c) => c.types.includes('administrative_area_level_1')) || {};
		const { long_name: country = '' } =
			place.address_components.find((c) => c.types.includes('country')) || {};
		const { long_name: city = '' } =
			place.address_components.find((c) => c.types.includes('locality')) || {};

		this.setState({ address });
		this.props.onLocationSelected(address);

		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => console.log('Success', latLng))
			.catch((error) => console.error('Error', error));
	};

	render() {
		return (
			<PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div style={{ position: 'relative' }}>
						<input
							{...getInputProps({
								placeholder: 'Search your location',
								className: 'location-search-input',
							})}
						/>
						<div
							style={{
								position: 'absolute',
								zIndex: '10000',
								textAlign: 'left',
								marginBottom: '20px',
								backgroundColor: 'white',
							}}
						>
							{loading && <div>Loading...</div>}
							{suggestions.map((suggestion, i) => {
								const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
								const style = suggestion.active
									? { backgroundColor: '#D0ECED', cursor: 'pointer', margin: '10px' }
									: { backgroundColor: '#ffffff', cursor: 'pointer', margin: '10px' };
								return (
									<div key={i} {...getSuggestionItemProps(suggestion, { className, style })}>
										<span key={i}>{suggestion.description}</span>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		);
	}
}

export default LocationSearchInput;