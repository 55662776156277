import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import brand from "../../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../shared-comps/back-btn";
import { getJobRolesIndustries as getJobRolesIndustriesApi } from "../../../../../api/job-roles";
import { updateSeekerJobDataByKey as updateSeekerJobDataByKeyApi, 
    updateSeekerJobDataByKeyValue as updateSeekerJobDataByKeyValue  } from "../../../../../api/job-seeker";
import { generateHexUUID } from "../../../../../utils";

const SeekerSignUpStep4 = ({_userId, _onResp}) => {
const [jobIndustryListed, setjobIndustryListed] = useState([])
const [IndusrySelected, setIndustrySelected] = useState([])
const [industrySelectedForInput, setindustrySelectedForInput] = useState([])
const [userCreatedRole, setUserCreatedRole] = useState('')
const [preferredIndustry, setPreferredIndustry] = useState('')

useEffect(() => {
    getJobRoles()
}, [])

function getJobRoles(){
    getJobRolesIndustriesApi().then((_resp) => {
        if(_resp.data.message === 'success'){
            const records = _resp.data.result
            const filtered_records = records.filter(rec => rec['usertype'] === '3')
            const final_filtered_records = filtered_records.filter(rec => !['654a45adba3d8ee35022a620', '655b6742ba3d8ee35022d503'].includes(rec['_id']))
            setjobIndustryListed(final_filtered_records)
        }else{

        }
    }).catch(() => {

    })
}

function onProceedClick() {
    if([undefined, null, ''].includes(userCreatedRole)  && [undefined, null, ''].includes(preferredIndustry)){
        toast.warning('Please Provide Industry')
        return
    }
    updateSeekerAssDetails()
    updateCreateProfileStep()
}

function updateSeekerAssDetails(){
    let industry = ''
    if(userCreatedRole !== ''){
        industry = userCreatedRole
    }else{
        industry = preferredIndustry
    }
    const obj = {
        "_id": generateHexUUID(),
        "option": industry,
        "category": "jobrole",
        "usertype": '',
        "priority": 1,
        "addedby": '',
        "userId": '',
        "status": 2,
        "orderBy": null
    }
    const data = {'industry': [obj]}
    updateSeekerJobDataByKeyApi(_userId, data).then((_resp) => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

function updateCreateProfileStep(){
    const obj = {'selfAssessmentResultSteps': 5}
    updateSeekerJobDataByKeyValue(_userId, obj).then((_resp) => {

    }).catch((_resp) => {

    })
}

return <>
<section id="job-role-steps">
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" className="w-80px" />
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-2" />
            <div className="col-lg-8">
                <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                        <fieldset>
                            <div className="form-card">
                                <h3 className="job-que">Select 1 field you're interested in exploring</h3>
                                <div className="role-wrapper skyblue-radio-btn">
                                    {jobIndustryListed.map((data, i) => (
                                        <div key={i} className="role-col">
                                            <div key={i + 2} className="inputGroup">
                                                <input  key={i + 3} id={data?._id} name="radio" readOnly checked={IndusrySelected.includes(data._id)} type="radio" />
                                                <label key={i + 4}
                                                    onClick={() => {
                                                        setindustrySelectedForInput("");
                                                        setIndustrySelected([data._id]);
                                                        setUserCreatedRole(data.option);
                                                        setPreferredIndustry('')
                                                    }} className="text-capitalize" htmlFor={data?._id} >
                                                {data.option}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="mt-4 col-6">
                                    <span>Don't See your Preferred Industry?</span>
                                    <input onChange={(e) => {
                                        setPreferredIndustry(e.target.value)
                                        setUserCreatedRole('')
                                        setIndustrySelected('')
                                        }} value={preferredIndustry} type="text" className="form-control" placeholder="Enter Industry..." />
                                </div>
                            </div>
                            <input type="button" name="next" className="next action-button" value="" onClick={() => {onProceedClick()}} />
                        </fieldset>
                    </form>
                </div>
                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                Step 4/16
                            </h2>
                        </div>
                    </div>
                    <div className='progress border'>
                        <div className='progress-bar' role='progressbar' style={{ width: '25%' }}  aria-valuenow='25' aria-valuemin='0' aria-valuemax='100' />
                    </div>
                </div>
            </div>
            <div className="col-lg-2" />
        </div>
    </div>
</section>
</>
}

export default SeekerSignUpStep4