import React, { useState, useEffect, useMemo } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { toast } from "react-toastify";
import brand from "../../../../assets/images/logo-icon-blk.png";

function HardSkillUpdate1({
  prevHardSkill,
  hard1Modal,
  setHard1Modal,
  updateProfileFunc,
  onAddSkill,
}) {
  const [currentAddedSkill, setCurrentAddedSkill] = useState(
    prevHardSkill || []
  );
  const [userCreatedRole, setUserCreatedRole] = useState("");
  const [isAddButton, setAddButton] = useState(false);

  useEffect(() => {
    if (userCreatedRole !== "") {
      const userSearch = userCreatedRole.toLowerCase();
      const data = currentAddedSkill.filter(
        (data) => data.skill.option.toLowerCase() === userSearch
      );

      if (data.length === 0) {
        setAddButton(true);
      } else {
        setAddButton(false);
        toast.error("This skill is already in the list!", {
          position: "top-right",
          autoClose: 1000,
        });
      }
    }
  }, [userCreatedRole]);

  const addNewSkill = (e) => {
    e.preventDefault();
    if (userCreatedRole) {
      const newSkill = {
        id: Date.now().toString(), // Generate a temporary ID
        skill: {
          explevel: "Entry",
          option: userCreatedRole,
          exp: "0-2 years",
        },
      };

      setCurrentAddedSkill([...currentAddedSkill, newSkill]);
      setUserCreatedRole("");
      setAddButton(false);

      if (onAddSkill) {
        onAddSkill(newSkill);
      }

      // toast.success("Your skill has been added successfully!", {
      //   position: "top-right",
      //   autoClose: 1000,
      // });
    }
  };

  const savingInfo = () => {
    if (currentAddedSkill.length > 0) {
      updateProfileFunc("hardSkill", currentAddedSkill);
      setHard1Modal(false);
    } else {
      toast.error("Please add hard skills!", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  const getValue = (dataId, value) => {
    const index = currentAddedSkill.findIndex((item) => item.id === dataId);

    if (index !== -1) {
      const updatedSkill = { ...currentAddedSkill[index] };
      if (value <= 2) {
        updatedSkill.skill.explevel = "Entry";
        updatedSkill.skill.exp = "0-2 years";
      } else if (value > 2 && value < 5) {
        updatedSkill.skill.explevel = "Mid";
        updatedSkill.skill.exp = "3-5 years";
      } else if (value > 5 && value < 9) {
        updatedSkill.skill.explevel = "Senior";
        updatedSkill.skill.exp = "6-9 years";
      } else if (value > 9) {
        updatedSkill.skill.explevel = "Expert";
        updatedSkill.skill.exp = "10+ years";
      }

      const updatedSkills = [...currentAddedSkill];
      updatedSkills[index] = updatedSkill;
      setCurrentAddedSkill(updatedSkills);
    }
  };

  function removeSkill(_skill){
    const updated_skills = currentAddedSkill.filter(s => s.skill.option !== _skill.skill.option)
    setCurrentAddedSkill(updated_skills)
    updateProfileFunc('hardSkill', updated_skills)
  }

  return (
    <React.Fragment>
      {hard1Modal && (
        <Modal show={hard1Modal} onHide={() => setHard1Modal(false)}>
          <ModalBody>
            <button
              className="position-absolute"
              style={{
                width: "100px",
                backgroundColor: "transparent",
                top: "20px",
                left: "0px",
                fontSize: "30px",
                zIndex: "333",
                border: "none",
              }}
              onClick={() => setHard1Modal(false)}
            >
              <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
            </button>
            <section id="skills-steps">
              <div className="discover-personality-top-cnt">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <img src={brand} alt="brand" />
                      <h3>Add or Update Your Hard Skills</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="card" style={{ borderWidth: 0 }}>
                      <form id="msform" onSubmit={addNewSkill}>
                        <fieldset>
                          <div className="form-card">
                            <div className="enter-hard-skills">
                              <h5>
                                <strong>Hard skills</strong> are specific skills
                                you use on the job.
                              </h5>
                              <div className="d-flex position-relative">
                                <input
                                  type="text"
                                  placeholder="Type your hard skills here..."
                                  value={userCreatedRole}
                                  onChange={(e) =>
                                    setUserCreatedRole(e.target.value)
                                  }
                                />
                                {isAddButton && (
                                  <button
                                    className="mx-1 btn btn-outline-primary"
                                    type="submit"
                                  >
                                    Add Skill
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="exp-range-slider">
                              {currentAddedSkill.map((data, i) => (
                                <div key={i} className="skill-range-slider">
                                  <div className="slidecontainer">
                                    <div className="d-flex justify-content-between">
                                      <h6>{data.skill.option}</h6>
                                      <i onClick={() => {removeSkill(data)}} type='button' className="fa fa-minus-circle"></i>
                                    </div>
                                    <input
                                      type="range"
                                      min="0"
                                      max="12"
                                      step={4}
                                      value={(() => {
                                        const exp = data.skill.exp;
                                        if (exp === "0-2 years") return 2;
                                        if (exp === "3-5 years") return 4;
                                        if (exp === "6-9 years") return 8;
                                        if (exp === "10+ years") return 12;
                                        return 2;
                                      })()}
                                      onChange={(e) =>
                                        getValue(data.id, e.target.value)
                                      }
                                      className="slider form-range"
                                    />
                                    <p>
                                      {data.skill.explevel} ({data.skill.exp})
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <input
                            type="button"
                            name="next"
                            className="form-btn mt-4"
                            value="Update"
                            onClick={savingInfo}
                          />
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default HardSkillUpdate1;
