import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getJobRolesJobRoles as getJobRolesJobRolesApi } from "../../../../api/job-roles";
import brand from "../../../../assets/images/logo-icon-blk.png";
import BackBtn from "../../../public/sign-up/shared-comps/back-btn";
import { updatePosterJobDataByKey as updatePosterJobDataByKeyApi } from "../../../../api/poster";
import { generateHexUUID } from "../../../../utils";

const SeekerSignUpStep2 = ({_jobId, _onResp }) => {
const [jobRoleSelected, setJobRoleSelected] = useState([])
const [jobRoleForTextField, setJobRoleForTextField] = useState('')
const [userCreatedRole, setUserCreatedRole] = useState('')
const [jobListed, setJobListed] = useState([])

useEffect(() => {
    getJobRoles()
}, [])

function getJobRoles(){
    getJobRolesJobRolesApi().then((_resp) => {
        if(_resp.data.message === 'success'){
            setJobListed(_resp.data.result)
        }else{

        }
    }).catch(() => {

    })
}

function onProceedClick() {
    if([undefined, null, ''].includes(userCreatedRole)){
        toast.warning('Please Select Preferred Culture')
        return
    }
    updateSeekerAssDetails()
}

function updateSeekerAssDetails(){
    const obj = {
        '_id': generateHexUUID(),
        'option': userCreatedRole,
        'category': "jobindustry",
        'usertype': "3",
        'priority': 1,
        'addedby': "1",
        'userId': '',
        'status': 1,
        'orderBy': 1,
        'createdAt': '',
        'updatedAt': ''
    }
    const data = {'industry': obj}
    updatePosterJobDataByKeyApi(_jobId, data).then(() => {

    }).catch(() => {

    }).finally(() => {
        _onResp('next', null)
    })
}

return <>
<section id="job-role-steps">
    <BackBtn onClick={() => {_onResp('back', null)}}/>
    <div className="discover-personality-top-cnt">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" style={{width: "80px"}}/>
                </div>
            </div>
        </div>
    </div>
    
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-2"/>
            <div className="col-lg-8">
                <div className="card" style={{borderWidth: 0}}>
                    <form id="msform">
                        <fieldset>
                            <div className="form-card d-flex align-items-center justify-content-center flex-column">
                                <div className="col-12 col-lg-8">
                                    <h3 className="job-que">What's your preferred company culture?</h3>
                                </div>

                                <div className="role-wrapper">
                                    {jobListed.map((data, i) => (
                                        <div key={i + 3} className="role-col">
                                            <div key={i} className="inputGroup">
                                                <input key={i + 1} id={data?._id} name="radio" type="radio" readOnly checked={jobRoleSelected.includes(data._id)}
                                                />
                                                <label key={i + 2} htmlFor={data?._id} className="text-capitalize"
                                                    onClick={() => {
                                                        setJobRoleForTextField("");
                                                        setJobRoleSelected([data._id]);
                                                        setUserCreatedRole(data.option);
                                                    }}>
                                                    {data.option}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <input type="button" name="next" className="next action-button"  value="" onClick={onProceedClick} />
                            <div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h2 className='steps'  style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}>
                                            Step 2/16
                                        </h2>
                                    </div>
                                </div>
                                <div className='progress border'>
                                    <div className='progress-bar' role='progressbar' style={{ width: '12.5%' }}  aria-valuenow='12.5' aria-valuemin='0' aria-valuemax='100' />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div className="col-lg-2"/>
        </div>
    </div>
</section>

</>
}
    
export default SeekerSignUpStep2